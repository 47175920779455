@import './shared';

.data-error-container {

    p {
        margin: 20px;
        font-size: 1rem;
    }

    button {
        cursor: pointer;
        @include btnStyle;
        border: none;
    }
}