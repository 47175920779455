@import './shared.scss';

.navbar {
    @include centerChilds;

    .background_image {
        position: absolute;
        z-index: -1;
        top: 0;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        @include lg {
            display: none;
        }
    }

    .logo {
        align-self: flex-start;
        margin-inline-start: 40px;
        margin-inline-end: auto;

        img {
            width: 110px;
            // height: 70px;
        }
    }

    ul {
        transition: all 0.5s ease-out;
        list-style: none;
        @include centerChilds;
        margin-inline-end: auto;
        font-size: 18px;

        @include lg {
            position: absolute;
            flex-flow: column nowrap;
            width: 100%;
            opacity: 0;
            z-index: -1;
            top: -10px;
            background: $base-color;
           

            &.collapse {
                opacity: 1;
                top: calc($nav-heigth - 5px);
                z-index: 1000;
            }
        }

        li {
            margin: 5px 20px;
            font-weight: bold;

            @include lg {
                margin: 10px;
                text-align: center;
            }

            a {
                text-decoration: none;
                color: $text-color;

                &.active {
                    position: relative;
                    color: $primary-color;
                 
                    &::before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: $primary-color;
                        bottom: -12px;
                        left: calc(50% - 4px);

                        @include lg {
                            top: 7px;
                            left: -15px;
                        }
                    }
                }
            }
        }


    }

    .collapse-icon {
        padding: 0 30px;
        display: none;

        i {
            font-size: 2rem;
            cursor: pointer;
            transform: rotate(180deg);
        }

        @include lg {
            display: block;
        }
    }

}