@import './shared';

.services-page-container {
    margin-top: 25px;
    text-align: center;

    h3 {
        color: $primary-color;
        font-size: $sectionTitlesFontSize;
    }

    p {
        padding: 10px;
        font-size: 1.3rem;
        a {
            text-decoration: none;
            color: $primary-color;
        }
    }

  
}