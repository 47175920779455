@import './shared';


.recent-work-description{
    margin-top: 25px;
    text-align: center;
    border-radius: 32px;
    h3 {
        color: $primary-color;
        font-size: $sectionTitlesFontSize;
    }
    p{
        font-size: 1.3rem;
        padding: 10px;
        span{
            font-weight: 500;
        }
        a{
            text-decoration: none;
            color: $primary-color;
        }
        &:nth-of-type(2){
            font-weight: 500;
        }
    }

    .works-list{
        margin-top: 30px;
        @include centerChilds;
        flex-flow: row wrap;
    }
}