@import './shared.scss';

.footer {
    padding: 30px;

    @include md {
        padding: 30px 10px;
    }

    background: $dark-bg;
    background-image: url('../assets/footer-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    color: white;

    h3 {
        font-size: $sectionTitlesFontSize;
        color: $primary-color;
        padding-inline-start: 10px;
        font-weight: 500;
    }


    .form-container {
        form {
            @include centerChilds;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 30px 10px;

            .form-item {
                @include centerChilds;
                $width: 45%;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: $width;
                min-width: 250px;
                max-width: 700px;
                margin: 2px 10px;

                @include sm {
                    $width: 100%;
                    width: $width;
                    margin: 5px 10px;
                }


                label {
                    margin-inline-end: 10px;
                    flex-basis: 150px;
                    font-size: 1rem;
                    font-weight: 900;
                    margin: 10px;

                    .error {
                        margin-inline-end: 5px;

                        color: $errorColor;

                    }
                }

                input,
                textarea {
                    color: $base-color;
                    margin-right: auto;
                    flex-grow: 1;
                    display: inline-flex;
                    padding: 10px 15px;
                    border: none;
                    outline: none;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 30px;
                }

                input {
                    max-width: 275px;
                }

                textarea {
                    resize: none;
                    height: 80px;
                    border-radius: 15px;

                }

                &.text-area {
                    margin-top: 30px;

                    max-width: 1100px;
                    width: calc(2 * $width);
                    margin-bottom: 20px;

                    @include md {
                        margin-top: 15px;
                    }

                    @include sm {
                        margin-top: 5px;
                    }
                }

            }

            button {
                outline: none;
                border: none;
                flex-grow: 1;
                @include btnStyle;
                margin-top: auto;
                max-width: 220px;
                margin-bottom: 20px;
                background: $base-color;
                color: $text-color;
                font-weight: 600;

                &:hover {
                    background: $base-color;
                    color: $text-color;
                }

                @include md {
                    margin: 10px auto
                }

                span{
                    margin-inline-end: 5px
                }
            }
        }
    }

    .info-container {
        @include centerChilds;
        flex-flow: row wrap;
        align-items: flex-start;

        article {
            flex-grow: 1;
            min-width: 250px;
            margin-bottom: 20px;

            &:first-of-type {
                font-size: 1rem;

                h5 {
                    color: $primary-color;
                    font-size: 1.15rem;
                }

                p {
                    padding: 10px;

                    i {
                        color: $primary-color;
                        font-size: 0.8rem;
                        margin-inline-end: 10px;

                        &[class*="fa-brands"] {
                            font-size: 1rem;
                        }
                    }

                }
            }

            &:nth-of-type(2) {
                h5 {
                    color: $primary-color;
                    font-size: 1.15rem;
                }

                ul {
                    list-style: none;
                    padding-inline-start: 20px;

                    li {
                        position: relative;
                        padding: 10px;

                        &::before {
                            @include addBolts(8px);
                            top: 18px;

                            @include sm {
                                top: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .copy-writer {
        padding: 10px 5px;
        text-align: center;
        font-weight: 700;
    }
}