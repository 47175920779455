@import './shared';

.our-customer {
    background: $dark-bg;
    color: $base-color;
    padding: 30px;

    h3 {
        font-size: $sectionTitlesFontSize;
        padding-inline-start: 10px;
        font-weight: 500;
    }

    .data-loader-container {
        @include centerChilds;
    }

    .image-container {
        display: flex;
        flex-flow: row nowrap;
        overflow-y: auto;

        .owl-stage-outer {
            max-height: 150px;

            .owl-stage {

                .owl-item {
                    .image {
                        width: inherit;

                        img {

                            width: 101%;
                            height: 100%;
                        }
                    }
                }
            }

        }



        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    }
}