@import './shared.scss';

.home-about {

    header,
    footer {
        text-align: center;
        padding-top: 40px;

        h3 {
            font-size: $sectionTitlesFontSize;
        }

        p {
            font-size: 1.1rem;
            padding-top: 10px;

            span {
                font-weight: 600;
            }
        }
    }

    .content {
        @include centerChilds;
        padding: 30px;
        gap: 30px;


        h3 {
            $fontSize: 5rem;
            font-weight: 100;
            text-align: start;
            font-size: $fontSize;
            flex-basis: 40%;

            // transition: all 0.5s ease-in-out;
            &:first-of-type {
                text-align: center;
                font-size: $fontSize + 1rem;
                color: $primary-color;
            }


            @include md {
                $fontSize:2.5rem;
                font-size: $fontSize;

                &:first-of-type {
                    font-size: $fontSize + 1rem;

                }
            }

            @include sm {
                $fontSize:2rem;
                font-size: $fontSize;

                &:first-of-type {
                    font-size: $fontSize + 0.5rem;

                }
            }

        }


        @include md {
            padding: 15px;
            gap: 15px;
        }
    }


    footer {
        margin-bottom: 30px;
        @include md{
            padding-top: 10px;
        }
        a {
            display: inline-block;
            @include btnStyle;
            border: 1px solid $black-color;
            @include setColorAndBackground($text-color, $base-color);
            transition: color 0.8s ease, background-color 0.8s ease;
            margin-top: 25px;

            &:hover {
                @include setColorAndBackground($base-color, $text-color);

            }
        }
    }
}