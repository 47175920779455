@import './shared';

.work-item {
    flex-basis: 45%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    flex-grow: 1;
    border-radius: 30px;
    margin: 20px;
    padding: 15px;
    overflow: hidden;
    header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        @include md{
            flex-flow: column nowrap;
        }

        .image-container {
            flex-basis: 30%;
            min-width: 150px;
            @include centerChilds;
            img {
                width: 100%;
                max-width: 100px;
                border-radius: 25px;
            }
        }

        .description-container {
            margin-top: 10px;
            flex-grow: 1;
            @include centerChilds;
            justify-content: space-between;

            div {
                text-align: start;

            }
            h5{
                font-size: 1.3rem;
            }

            i {
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                margin: calc(5px + 0.003vw);
                font-size: 1.5rem;

                &.open {
                    transform: rotate(-180deg);
                }
            }
        }
    }

    .details {
        div{
            display: flex;
            justify-content: flex-start;
            p{
                &.key{
                    font-weight: 600;
                }
                font-weight: 300;
                text-align: start;
                padding: 10px;
                li{
                    padding: 10px 0;
                }
            }
        }

    }
}