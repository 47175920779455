@import './shared';

.hardware-brands-container{
    max-width: 1000px;
    margin: 10px auto;
    text-align: center;
    h3{
        font-size: 2rem;
    }
    p{
        font-size: 1.5rem;
    }

    .owl-stage-outer {


        .owl-stage {

            .owl-item {
                flex-basis: 20%;
                max-width: 200px;
                div {

                    @include centerChilds;
                    margin-inline-start: 10px;
                    margin-inline-end: 10px;

                    img {
                        width: 100%;
                       

                    }

                   
                }

            }
        }
    }

    .owl-nav {
        button {
           width: 30px;
            &.owl-prev {
                background: $base-color;
                left: 0;
            }
    
            &.owl-next {
                background: $base-color;
                right: 0;
                &::after{
                    left: 0;
                }
            }
           
        }
    }
}