@import './shared.scss';

.hero-section {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - $nav-heigth);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    article {
        background-color: $primary-color;
        background-clip: text;
        -webkit-text-fill-color: $primary-color;

        p {
            user-select: none;
            $padding-start: 15%;
            font-size: 9rem;
            color: transparent;
            font-weight: 800;
            padding-inline-start: $padding-start;
            mix-blend-mode: difference;

            &:nth-of-type(1) {
                padding-top: 7vh;
            }

            &:nth-of-type(2) {
                padding-inline-start: $padding-start + 3%;
            }


            @include sm {
                font-size: 6rem;
            }
        }

        img {
            @include setAsBackground;
            height: 100%;
        }
    }

    footer {
        position: relative;

        overflow: hidden;
        background: $primary-color;
        color: $base-color;
        text-align: center;
        padding: 15px;

        p {
            padding-top: 10px;
            font-size: 1.3rem;

            &:first-of-type {
                font-weight: bold;
                font-size: 1.7rem;
            }
        }

        .btn-container {
            @include centerChilds;
            padding: 15px 0;
            flex-flow: row wrap;

            a {

                @include btnStyle;
                @include setColorAndBackground($base-color, $text-color);
                transition: color 0.8s ease, background-color 0.8s ease;

                @include md {
                    margin: 10px
                }

                &:hover {
                    @include setColorAndBackground($text-color, $base-color)
                }
            }
        }

        img {
            @include setAsBackground;
            z-index: 0;
            width: 100%;
            inset: 0;
        }
    }
}