@import './shared';


.clients-category {
    $line-height: 60px;
    border: 1px solid #121212;
    max-width: 1100px;
    width: 90%;
    margin: 15px;
    margin-top: $line-height;
    border-radius: 30px;
    position: relative;
    padding: 0 0 $line-height ;

    div {
        position: relative;
        top: calc(-1 * ($line-height / 2));
        font-size: 2.6rem;
        h5 {
            display: inline;
            padding: 0 20px;
            background: $base-color;
        }
    }

    article{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        img {
            max-width: 150px;
            border-radius: 25px;
            margin: 15px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
        }
    }

}