@import './shared';

.categories-component {
    display: flex;
    justify-content: center;
    .categories-container {
        display: flex !important;
        flex-flow: row nowrap;
        overflow-y: auto;
        gap: 20px;
        position: relative;
        padding: 0 20px;
        overflow-x: hidden;

        .owl-stage-outer {


            .owl-stage {

                .owl-item {
                    flex-basis: 10%;
                    max-width: 100px;
                    flex-grow: 1;
                    cursor: pointer;

                    div {

                        text-align: center;
                        @include centerChilds;
                        flex-flow: column nowrap;

                        img {
                            width: 100%;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            box-shadow:0px 0px 4px rgba(0, 0, 0, 0.25) ;
                        }

                        p {
                            margin-top: 5px;
                            font-size: 0.8rem;
                        }

                        &.active {
                            p {
                                font-weight: 900;
                            }
                        }
                    }

                }
            }
        }

       


    }
}