@import './shared';

.main-section {
    min-height: calc(100vh - $nav-heigth);
    @include centerChilds;
    align-items: stretch;
    flex-wrap:wrap ;
    article {
        flex-basis:  30%;
       
        &.title-container {
            font-size: 10rem;
            font-weight:900 ;
            color: $primary-color;
            text-align: end;
            @include md{
                margin-top: 20px;
                text-align: center;
            }
        }

        &.details-container {
            flex-basis:  60%;
            color: $black-color;
            padding: 15px ;
            padding-inline-start: 40px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-evenly;
          p{
            font-size: 1.1rem;
            position: relative;
            padding: 10px;
            line-height:50px;
            span{
                font-weight: 600;
            }
            &::before{
                @include addBolts(7px);
                top:30px;
                background: $black-color;;
            }
          }
        }

        @include md{
            flex-basis:  100%;
            flex-grow: 1;
            padding: 5px;
        }
    }
}