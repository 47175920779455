@import './shared';


.search-container {
    padding: 10px 30px 10px 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    div {

        flex-grow: 1;

        .title {
            position: relative;
            font-weight: 900;
            bottom: 10px;
        }

        .wrapper {
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            .input-container {
                max-width: 250px;
                margin-inline-end: 20px;
                display: inline-block;

                width: 100%;
                border: 1px solid $text-color;
                border-radius: 30px;
                position: relative;
                overflow: hidden;


                input {

                    border: 0;
                    outline: 0;
                    padding: 10px;
                    width: 100%;
                }

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 8px;
                    top: 4px;
                    padding: 5px;
                    background: $base-color;
                }
            }
        }

        p {
            margin-top: 10px;
            font-weight: 400;
            @include centerChilds;

            span {
                display: inline-block;
                margin-inline-start: 10px;


                &.query {
                    font-weight: 500;
                    max-width: 250px;

                    @include tn {
                        max-width: 80px;
                    }

                    @include ellipsisText(1)
                }

                &.result-count {
                    border-radius: 30px;
                    background: $lightGray;
                    padding: 5px 10px;
                    white-space: nowrap;
                }
            }


        }
    }

    a {
        @include btnStyle;
        color: $black-color;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        padding: 10px 20px;

        i {
            margin: 0 10px;
        }
    }

    @include md {
        padding: 15px;
    }
}