* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


// Colors
$primary-color: #0083B7;
$base-color: #fff;
$text-color: #212121;
$black-color: #000;
$dark-bg:#212121;
$whatsAppColor:#25D366;
$lightGray:#EBEBEB;
$borderColor:#CECECE;
$errorColor:#FF4F4F;




$nav-heigth: 90px;
$sectionTitlesFontSize:3.5rem;
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;

.router-container {
    overflow-x: hidden;
  
    > :nth-child(2) {
        min-height: 90vh;
        overflow-y: hidden;
        // &::-webkit-scrollbar {
        //     width: 0px;
        
        // }
    }

}





// Phone devices
@mixin tn {
    @media (max-width: #{$screen-tn-max}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}


@mixin textResponsive {
    @include lg {
        background: red
    }

    @include md {
        background: yellow;
    }

    @include sm {
        background: green;
    }

    @include tn {
        background: #888;
    }
}

@mixin centerChilds {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin setColorAndBackground($color, $bg) {
    color: $color;
    background: $bg;
}

@mixin setAsBackground {
    position: absolute;
    z-index: -1;
}


@mixin btnStyle {
    display: inline-block;
    font-weight: 200;
    font-size: 1rem;
    margin: 10px 30px;
    text-decoration: none;
    padding: 10px 50px;
    border-radius: 50px;
}

@mixin background {
    $width: 90%;
    z-index: -1;
    position: absolute;
    width: $width;
    margin: 0 auto;
}

@mixin ellipsisText($numberOfLines:1) {
    overflow: hidden;
    word-wrap:break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $numberOfLines;
    -webkit-box-orient: vertical;
}

@mixin addBolts($size:10px) {
    content: "";
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    background: $primary-color;
    top: $size;
    left: calc(-1 * $size);
}

/* width */
::-webkit-scrollbar {
    width: 5px;

}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    transition: 0.5s;
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}



:root {
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    scroll-behavior: smooth;

    @include lg{
        font-size: 18px;
    }
    @include md{
        font-size: 16px;
    }
    @include sm{
        font-size: 14px;
    }
    // @include tn{
    //     font-size: 10px;
    // }
  
}

.owl-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;


    button {
        position: absolute;
        font-size: 50px;
        bottom: 0;
        top: 0;
        padding: 5px;

        span {
            font-size: 2rem;
            color: $black-color;
        }

        &.owl-prev {

            left: 0;
        }

        &.owl-next {
            right: 0;
        }

        &.disabled {
            span {
                opacity: 0.6;

            }
        }
    }
}