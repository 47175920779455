@import './shared.scss';

.home-services {
    background: $primary-color;
    color: $base-color;
    padding: 30px 10px;
    text-align: center;

    h3 {
        font-size: $sectionTitlesFontSize;
    }


    .services-container {
        padding: 40px 30px 30px;
        @include centerChilds;
        flex-flow: row wrap;
        gap: 5rem;

        div {
            background: $base-color;
            border-radius: 30px;
            display: flex;

            a {
                text-decoration: none;
                color: $primary-color;
                position: relative;
                $divSize: 300px;
                flex-basis: $divSize;
                width: $divSize;
                min-height: $divSize;
                z-index: 1;
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-around;
                padding:25px 15px;
                cursor: pointer;
                overflow: hidden;
                transition: box-shadow 0.3s ease-in-out;

                p {
                    z-index: 1;
                    font-size: 2.3rem;
                    font-weight: 200;
                    text-align: center;

                    @include sm {
                        font-size: 3rem;
                    }
                }

                .image-container {
                   
                    @include centerChilds;
                    transition: all 0.5s ease-in-out;

                    img {
                     
                        max-width: 100px;
                    }
                }


                span {
                    transition: all 0.3s ease-in-out;
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    font-size: 1.2rem;
                    transform: translate(100%);
                    color: $text-color;
                    opacity: 0;
                }

            }

            &:hover {
                box-shadow: 2px 2px 24px -6px $base-color;
                transform: scale(1.1);

                span {
                    opacity: 1;
                    transform: translate(0);
                }


            }
        }
    }
}