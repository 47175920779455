@import './shared';

.clients-page {
    margin-top: 25px;
    text-align: center;
    border-radius: 32px;

    h3 {
        color: $primary-color;
        font-size: $sectionTitlesFontSize;
    }

    p {
        padding: 10px;
        
        max-width: 1000px;
        font-size: 1.3rem;
        margin: 5px auto;
        span {
            font-weight: 600;
        }
    }

    .clients-category-list{
        margin-top: 50px;
        margin-bottom: 50px;
        @include centerChilds;
        flex-direction: column;
    }
}