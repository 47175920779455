@import './shared';

.service-item{
    @include centerChilds;
    padding: 25px;
    gap: 25px;
    $smMarginTop:25px;

    &.right{
        flex-direction: row-reverse;
        @include sm{
            margin-top: $smMarginTop;
            flex-direction: column-reverse;
        }
    }


    .description-container{
        flex-basis: 50%;
        flex-grow: 1;
        h5{
            text-align: start;
            color: $primary-color;
            font-size: 2.9rem;
            font-weight: 200;
        }
        p{
            text-align: justify;
            font-size: 1.3rem;
        }

        .technologies-container{
            display: flex;
            flex-flow: row wrap;
            margin-top: 25px;
            li{
                text-align: start;
                flex-basis: 50%;
            }

        }
    }
    .image-container{
        flex-basis: 40%;
        img{
            width: 100%;
            max-width: 500px;
        }
    }

    @include sm{
        margin-top: $smMarginTop;
        flex-direction: column-reverse;
    }
}