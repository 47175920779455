@import './shared';

.loader-container{
    transition: all 0.5s ease-in-out;
    position: absolute;
    z-index: 5000;
    // width: 100vw;
    // height: 100vh;
    background: $primary-color;
    inset: 0;
    @include centerChilds;
    flex-direction: column;

    img{
        margin: 10px;
        width: 100%;
        max-width: 200px;
    }

    .welcome-msg{
        color: $base-color;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 2rem;
    }

}