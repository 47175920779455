@import './shared';

.product-item-details {
    margin-top: 40px;
    padding: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    gap: 25px 40px;

    article {
        flex-basis: 40%;
        min-width: 300px;

        &.galleria-container {
            max-width: 500px;
            overflow: hidden;
            .animated-image{
                position: relative;
                animation: fade;
                animation-duration: 0.5s;
                animation-fill-mode: both;
            }
            .p-galleria-indicator.p-highlight button{
                background: $primary-color;
            }
         .p-galleria-item-nav{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            transition:all 0.5s ease-in-out;
            @include setColorAndBackground($lightGray,$primary-color);


            &:hover{
                @include setColorAndBackground($primary-color,$lightGray);    
            }
         }
        }

        &.metadata-container {
            flex-grow: 1;
            max-width: 800px;
            h5 {
                font-size: 1.5rem;
            }

            .first {
                padding: 20px 0 5px;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                border-bottom: 1px solid $borderColor;

                .price {
                    font-weight: 700;
                    color: $primary-color;

                    span:nth-child(2) {
                        font-size: smaller;
                        font-weight: 500;
                    }
                }

                .save-item {
                    cursor: pointer;
                    span {
                        i {
                            margin-inline-start: 5px;
                        }
                    }

                }
            }

            .description{
                margin-top: 10px;
                font-size: 0.8rem;
                p{
                    font-weight: 700;
                   
                }
                li{
                    margin: 10px 0;
                }
            }
        }
    }
}


@keyframes fade {
    from{
        transform: scale(2);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
        z-index: -1;
    }
}

