@import './shared';

.about-us-plan-section {
    margin: 30px 0;
    padding: 30px;
    $depthAlignment: 200px;
    padding-bottom: $depthAlignment;
    @include centerChilds;
    flex-flow: row wrap;



    div {
        flex-grow: 1;
        flex-basis: 30%;
        max-width: 400px;

        @include md {
            flex-basis: 40%;
        }

        min-width: 250px;
        padding: 35px;
        // min-height: 400px;
        position: relative;
        text-align: center;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        overflow: hidden;
        margin: 10px;

        h5 {
            color: $primary-color;
            border-radius: 30px;
            font-size: 3.5rem;
            font-weight: 500;
            margin: 30px 5px;
        }

        p {
            font-size: 1.1rem;
            line-height: 35px;
            text-align: justify;
        }

        img {
            position: absolute;
            inset: 0;
            margin: 0 auto;
        }

        &:first-child {
            top: calc($depthAlignment / 2);
               @include lg{
                top: 0;
               }
        }

        &:last-child {
            top: $depthAlignment;
            @include lg{
                top: 0;
               }
        }

    }

    @include md {
        $depthAlignment: 10px;
        padding-bottom: $depthAlignment;
    }
}