@import "./shared.scss";

.integrated-shop {
  padding: 30px;
 
  @include lg {
    padding: 20px;
  }

  h3 {
    font-size: $sectionTitlesFontSize;
    position: relative;
  }

  article {
    position: relative;
    display: flex;
    flex-flow: row-reverse wrap;

    @include lg {
      flex-flow: column wrap;
    }

    .image-container {
      @include centerChilds;
      flex-basis: 50%;
      position: relative;
      bottom: 5vw;
      overflow: hidden;

    @include lg {
      bottom: 0px;
    }
      @include sm {
        flex-grow: 1;
      }

      img {
        width: 100%;
        max-width:400px
      }
    }

    .details-container {
      align-self: center;
      color: $primary-color;
      font-size: 2rem;
      flex-basis: 50%;
      overflow: hidden;
      flex-grow: 1;

      p {
        font-weight: 200;
        margin: 15px 0;
        span{
          font-weight: 600;
      }
      }

      ul {
        font-size: 1.5rem;
        @include centerChilds;
        flex-wrap: wrap;

        li {
          width: 45%;
          list-style: none;
          color: $text-color;
          position: relative;
          padding-inline-start: 10px;
          margin-top: 15px;

          &:before {
            @include addBolts(10px)
          }
        }
      }

      .btn-container {
        @include sm{
          @include centerChilds;
        }
        a {
          display: inline-block;
          @include btnStyle;
          @include setColorAndBackground($base-color, $text-color);
          transition: color 0.8s ease, background-color 0.8s ease;
          margin-top: 30px;
          padding: 10px 70px;

          &:hover {
            @include setColorAndBackground($text-color, $base-color)
          }
        }
      }

    }
  }
}