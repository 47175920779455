@import './shared';

.shop-page {
    margin-top: 25px;
   
    header {
        display: flex;
        flex-flow: row wrap;
        padding: 30px;
        justify-content: space-around;
        gap: 20px 0;
        img{
            width: 100%;
        }
        h5 {
            color: $primary-color;
            font-size: 4.3rem;
            font-weight: 900;
        }

        .categories-component {
            max-width: 1000px;
            width: 100%;
        }


    }



    .global-container {
        display: flex;
        flex-flow: row nowrap;

        @include sm {
            flex-direction: column;

        }

        .filter-btn-container {
            display: none;
            padding: 10px;

            button {
                border: none;
                outline: none;
                border-radius: 30px;
                padding: 5px 15px;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
                background: transparent;

                i {
                    margin-inline-end: 5px;
                }
            }

            @include sm {
                display: block;

            }


        }

        // position: relative;
        .filters-container {
            flex-basis: 30%;
            max-width: 350px;

            @include sm {
                max-width: none;
            }
        }

        .products-container {
            flex-basis: 70%;
            flex-grow: 1;
            min-height: 50vh;

            @include sm {
                flex-basis: 100%;
            }
        }
    }
}