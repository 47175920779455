@import './_shared.scss';

.recent-work {
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
    @include centerChilds;
    flex-direction: column;

    @include md{
        padding: 30px 10px;
    }
    h3 {
        font-size: $sectionTitlesFontSize;
    }

    .work-item {
        flex-grow: 1;
        width: 100%;

        transition: all;
        max-width: 800px;
        border-radius: 30px;
        background:$primary-color;
        margin-top: 25px;
        padding: 20px;
        @include centerChilds;
        flex-flow: row-reverse wrap;
        overflow: hidden;

        .details {
            flex-basis: 50%;
            color: white;
            flex-grow: 1;

            p {
                font-size: 2rem;
                text-align: start;
                font-weight: bold;
                padding-inline-end: 10px;
            }

            ul {
                margin: 25px 15px 0;
                text-align: start;

                li {
                    font-weight: 200;
                    margin-top: 10px;
                    font-size: 1.2rem;
                }
            }
        }

        .image-container {
            flex-basis: 50%;
            min-width: 250px;
            margin-bottom: 25px;
            flex-grow: 1;

            img {
                width: 100%;
                max-width: 250px;
            }
        }
    }
}