@import './shared';

.contact-us-page {
    margin-top: 50px;
    text-align: center;

    h3 {
        color: $primary-color;
        font-size: $sectionTitlesFontSize;
    }

    .carts-conatiner {
        margin-top: 50px;

        .carts-group {

            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            div {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                margin: 15px;
                margin-top: 50px;
                border: 4px solid $primary-color;
                border-radius: 30px;
                padding: 0 15px 15px;
                flex-grow: 1;
                flex-basis: 50%;
                max-width: 300px;

                @include md {
                    max-width: none;
                }

                img {
                    position: relative;
                    max-width: 93px;
                    top: calc(-1 * (93px / 2))
                }

                h5 {
                    font-size: 1.2rem;

                    &:first-of-type {
                        position: relative;
                        top: -20px
                    }

                    &:nth-of-type(2) {
                        margin-top: 20px;
                    }
                }

                p {
                    margin: 10px 10px 20px;

                }

                a {
                    cursor: pointer;
                    transition: all 0.5s ease-in-out;
                    @include btnStyle;
                    border: 0;
                    padding: 15px 30px;
                    @include setColorAndBackground($base-color, $primary-color);

                    &:hover {
                        @include setColorAndBackground($base-color, $black-color);
                    }

                    i {
                        padding-inline-end: 10px;

                        &[class*="fa-brands"] {
                            font-size: 1.1rem;
                        }
                    }

                    margin-top: auto;
                }

                &.blue-bg {
                    background: $primary-color;
                    color: $base-color;

                    a {
                        @include setColorAndBackground($base-color, $whatsAppColor);

                        &:hover {
                            @include setColorAndBackground($whatsAppColor, $base-color);
                        }
                    }
                }
            }

            &:last-of-type {
                div {
                    max-width: 600px;
                }
            }
        }
    }
}