@import './shared';

.about-us-choose-us{
    margin:20px 0;
    padding:30px;
    text-align:center;
    h3{
        font-size: $sectionTitlesFontSize;
        color: $primary-color;
    }

    .carts-container{
        margin-top:30px;
        @include centerChilds;
        flex-flow:row wrap;
        gap:50px;

        div{
            flex-basis: 30%;
            min-width: 250px;
            flex-grow: 1;
            padding:30px;
            overflow:hidden;
            border-radius:30px;
            i{
                cursor:pointer;
                $size:70px;
                text-align:center;
                display: inline-block;
                width:$size;
                height:$size;
                color: $base-color;
                background:$primary-color;
                border-radius: 50%;
                padding: 10px;
                box-shadow: 0 0 0 $primary-color;
                transition: all 0.5s ease-in-out;
                font-size:45px;
                &:hover{
                    box-shadow: 0 0 0 1000px $primary-color;
                }
            }

            h5{
                font-size:1.7rem;
                padding:20px 5px;
            }
            p{
                text-align:justify;
            }
            
        }
    }
}