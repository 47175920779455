@import './shared';

.products-container {
    padding: 30px;
    @include md{
        padding: 30px 10px;
    }
    @include centerChilds;
    align-items: flex-start;
    .products-empty-section {
        margin-top: 50px;
        @include centerChilds;
        text-align: center;
        flex-flow: column nowrap;

        img {
            max-width: 265px;
        }

        div {
            margin-top: 25px;
            font-weight: 300;

            h5 {
                padding: 5px;
                font-size: 1.2rem;
            }

            p {
                padding: 5px;
                font-size: 0.9rem;

                a {
                    color: $primary-color
                }
            }
        }
    }


    .products-list{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;
        gap:30px;
        
    }

}