@import './shared';

.filters-container {
    padding: 20px;
    padding-inline-start: 50px;
    transition: all 0.4s ease-in-out;

    @include md {
        padding-inline-start: 10px;

    }

    @include sm {
        background: $base-color;
        position: fixed;
        inset: 0;
        z-index: -10;
        transform: translateY(-250px);
        opacity: 0;
        height: 100vh;
        overflow-y: auto;

    }

    &.open {
        z-index: 10;
        opacity: 1;
        transform: translateY(0px);
    }

    header {
        justify-content: space-between;
        padding: 0px;

        button {
            cursor: pointer;
            border: none;
            outline: none;
            padding: 5px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            background: $dark-bg;
            color: $base-color;
            display: none;

          
            @include sm {
                  @include centerChilds;

            }
        }
    }

    .filter-group {
        margin-top: 20px;

        i {
            cursor: pointer;
            transition: all 0.5s ease-in-out;

            &.close {
                transform: rotate(180deg);
            }
        }

        p {
            margin-bottom: 5px;
        }

        label {
            display: block;

            span {
                font-size: 14px;

                &.checkmark {
                    margin-inline-start: 5px;
                    position: relative;
                    bottom: 2px;
                }
            }
        }
    }

    .price-range-filter {
        margin: 25px 0;

        .range-containers {
            display: flex;
            padding: 15px 0;

            span {
                display: inline-block;

                &.after {
                    margin-inline-start: auto;
                }
            }
        }


    }

    .btn-container {
        @include sm {
            @include centerChilds;

        }

        button {
            @include btnStyle;
            margin: 10px 0;
            border: none;
            cursor: pointer;
            @include setColorAndBackground($black-color, $base-color);
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
            border-radius: 32px;
        }
    }

}