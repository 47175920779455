@import './shared';

.product-item {
    max-width: 300px;
    border-radius: 20px;
    border: 0.5px solid $borderColor;
    transition: box-shadow 0.5s ease-in-out;
    cursor: pointer;
    display:flex;
    flex-direction: column;
    footer {
        margin-top:auto;
        padding: 20px ;
        .product-name {
           @include ellipsisText(2);
           font-size: 0.8rem;
        }

        .product-status{
            margin-top: 5px;
            font-size: 0.7rem;
            color:$primary-color;
            text-align: start;
        }
        .cashPrice{
            
            text-align: start;
            margin-top: 25px;
        }



        div{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            i{
                margin-inline-start: auto;
            }
        }


        span{
           
            &.num{
                font-weight: 700;
            }
            &.suffix{
                font-size: smaller;
            }
            
        }
    }




    &:hover {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

    }
}