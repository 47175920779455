@import './shared';

.saved-items-page {
    margin-top: 25px;
    text-align: center;


    i {
        margin: 0 5px;
    }

    header {
        @include centerChilds;
        padding: 30px;

        @include md {
            padding: 10px;
        }

        p {
            cursor: pointer;
            padding-inline-end: 15px;
        }

        span {
            background: $black-color;
            min-height: 1px;
            flex-grow: 1;
        }

        h3 {
            position: relative;
            $headerSize: 1.7rem;
            padding: 30px 10px;
            font-size: $headerSize;
            font-weight: 400;

            i {
                font-size: calc($headerSize - 0.5rem);
            }


        }

        img {
            width: 100%;
        }
    }

    .empty-section {
        margin-top: 50px;
        @include centerChilds;
        flex-flow: row wrap;

        img {
            max-width: 265px;
        }

        div {
            font-weight: 300;

            h5 {
                font-size: 1.2rem;

            }

            p {
                font-size: 0.9rem;
            }
        }
    }

}